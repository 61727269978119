// Main Colours
$deep_sapphire: #0d1e71;
$dodger_blue: #3aa0f5;
$scooter_blue: #30bacd;
$aquamarine_blue: #6adbd6;

// Secondary Colours
$niagara_green: #0ba591;
$shamrock_green: #5bddb8;
$pastel_green: #8de28f;
$cerebra_green: #53d353;

// Tertiary Colors
$sunset_orange: rgb(244, 77, 77);
$casablanca_orange: #f9a846;
$yellow: #f6e148;
$charcoal: #393939;
$mine_shaft: #454545;
$gray: #7f7f7f;
$light_grey: #d3d3d3;
$off_white: #f4f4f4;
$ivory: #ffffed;
$white: #ffffff;
$lighter_grey: #e9ecef;

//fonts
$reversed: $white;
$main_header: $deep_sapphire;
$secondary_header: $deep_sapphire;
$third_header: $mine_shaft;
$body: $charcoal;
$fine_print: $charcoal;

// links
$link_default: #007bff;
$link_hover: $deep_sapphire;
$text_link_default: $dodger_blue;

// button
$primary_button: $dodger_blue;
$primary_button_hover: $link_default;
$secondary_button: $casablanca_orange;
$secondary_button_hover: #ff9a1e;
$danger_button: $sunset_orange;
$danger_button_hover: rgb(207, 58, 58);

// Bootstrap overwrites
$red: $sunset_orange;
$orange: $casablanca_orange;
$green: $niagara_green;
$teal: $scooter_blue;
$cyan: $dodger_blue;
$gray-dark: $charcoal;
