@use "src/assets/colours.scss";

.nav-pills {
  width: 280px;
  min-width: 280px;
  background-color: colours.$light_grey;
}

.nav-pills .nav-link {
  color: colours.$dodger_blue;
}

.nav-pills .nav-link.active {
  background-color: colours.$scooter_blue;
  color: colours.$white;
}

.nav-pills .nav-link.disabled {
  color: colours.$mine_shaft;
}
