.w-16ch {
  width: 16ch;
  min-width: 16ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.w-22ch {
  width: 22ch;
  min-width: 22ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.no-th-border th {
  border: none !important;
}
