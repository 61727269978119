@use "src/assets/colours.scss";

@font-face {
  font-family: "AvenirNextLTPro-Medium";
  src: url("/assets/fonts/36A29D_B_0.eot");
  src:
    url("/assets/fonts/36A29D_B_0.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/36A29D_B_0.woff2") format("woff2"),
    url("/assets/fonts/36A29D_B_0.woff") format("woff"),
    url("/assets/fonts/36A29D_B_0.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNextLTPro-Regular";
  src: url("/assets/fonts/36A29D_A_0.eot");
  src:
    url("/assets/fonts/36A29D_A_0.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/36A29D_A_0.woff2") format("woff2"),
    url("/assets/fonts/36A29D_A_0.woff") format("woff"),
    url("/assets/fonts/36A29D_A_0.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNextLTPro-Light";
  src: url("/assets/fonts/36A29D_8_0.eot");
  src:
    url("/assets/fonts/36A29D_8_0.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/36A29D_8_0.woff2") format("woff2"),
    url("/assets/fonts/36A29D_8_0.woff") format("woff"),
    url("/assets/fonts/36A29D_8_0.ttf") format("truetype");
}

@media print {
  .pagebreak {
    page-break-before: always;
  }
}

html,
body,
#root,
.app {
  height: 100%;
  margin: 0;
  display: flex !important;
  flex-direction: column;
  flex-wrap: nowrap !important;
}

.body,
p,
.p {
  font-family: "AvenirNextLTPro-Light";
  line-height: 1.45;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  font-family: "AvenirNextLTPro-Medium";
  margin-bottom: 0;
}

.body {
  padding: 1.5em 3em;
  position: relative;
}

h1,
.h1 {
  font-size: 40px;
  color: colours.$deep_sapphire;
}

h2,
.h2 {
  font-size: 33px;
  color: colours.$deep_sapphire;
}

h3,
.h3 {
  font-size: 30px;
  color: colours.$deep_sapphire;
}

th {
  color: colours.$deep_sapphire;
}

.data-card {
  box-shadow: 5px 5px 5px -3px rgba(0, 0, 0, 0.1);
  border: 1px solid #e5e1e5;
  border-radius: 10px;
  padding: 15px;
  background-color: #ffffff;
}

.tokens {
  height: 100%;
  margin: 15px 0;
  max-width: 100%;
  overflow-x: scroll;
}

.flex {
  display: flex;
}

.button-row {
  display: flex;
  & > button,
  & > a {
    margin-right: 15px;
  }
}

// Button Styles
.btn {
  padding: 5px 10px;
  border-radius: 10px;
  border: none;
  text-decoration: none;
  width: fit-content;
  &-primary {
    @extend .btn;

    background-color: colours.$primary_button !important;
    color: colours.$white !important;
    &:hover,
    &:focus {
      background-color: colours.$primary_button_hover !important;
    }
  }
  &-secondary {
    @extend .btn;

    background-color: colours.$secondary_button !important;
    color: colours.$white !important;
    &:hover,
    &:focus {
      background-color: colours.$secondary_button_hover !important;
    }
  }
  &-outline-primary {
    @extend .btn;

    color: colours.$primary_button !important;
    background-color: colours.$white !important;
    border: 1px solid colours.$primary_button !important;
    &:hover,
    &:focus {
      color: colours.$white !important;
      background-color: colours.$primary_button !important;
    }
  }
  &-outline-secondary {
    @extend .btn;

    color: colours.$secondary_button !important;
    background-color: colours.$white !important;
    border: 1px solid colours.$secondary_button !important;
    &:hover,
    &:focus {
      color: colours.$white !important;
      background-color: colours.$secondary_button !important;
    }
  }
  &-danger {
    @extend .btn;

    background-color: colours.$danger_button !important;
    color: colours.$white !important;
    &:hover,
    &:focus {
      background-color: colours.$danger_button_hover !important;
    }
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.7;
  }
}

.button-spinner {
  width: 1rem;
  height: 1rem;
  margin-left: 5px;
}

// table styles
.data-table {
  table-layout: fixed;
}

.table td,
.table th {
  vertical-align: middle;
}

.right-align-table-content {
  td {
    text-align: right;
  }
}

// General Use Styles
.data-label {
  color: colours.$deep_sapphire;
  font-weight: bold;
  vertical-align: baseline;
}

.company-description-cell {
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.green-icon,
.yellow-icon {
  margin: 0 3px;
}

.green-icon {
  color: colours.$niagara_green;
}

.yellow-icon {
  color: colours.$yellow;
}

.text-align-center {
  text-align: center;
}

.max-height-gauge-container {
  max-height: 300px;
}

.header-value-highlight {
  color: colours.$casablanca_orange;
}

.bar-graph-legend,
.custom-legend {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
  & li {
    margin: 0 5px;
  }
}

.w-30ch {
  max-width: 30ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hide-on-print {
  @media print {
    display: none;
  }
}

// DataTable
.datatable-header {
  cursor: pointer;
}

.sort-control {
  display: inline-flex;
  flex-direction: column;
}

.sort-control-icon {
  color: colours.$light_grey;
  height: 3px;
}

.sort-control-active {
  color: colours.$deep_sapphire;
}

.sort-control-inactive {
  color: colours.$light_grey;
}

.datatable-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Bootstrap overwrites
.dropdown-menu {
  --bs-dropdown-min-width: auto;
}

.alert {
  --bs-alert-margin-bottom: 0;
}

.no-click {
  cursor: default;
  a {
    cursor: default;
    pointer-events: none;
  }
}

.page-link {
  text-align: center;
}

.float-right {
  float: right;
}

.group-card {
  border-width: 3px;
}

.group-card-0 {
  border-color: colours.$dodger_blue;
}

.group-card-1 {
  border-color: colours.$niagara_green;
  border-style: dotted;
}

.group-card-2 {
  border-color: colours.$casablanca_orange;
  border-style: dashed;
}

.group-card-3 {
  border-color: colours.$deep_sapphire;
}

.group-card-4 {
  border-color: colours.$aquamarine_blue;
  border-style: dotted;
}

.group-card-5 {
  border-color: colours.$yellow;
  border-style: dashed;
}

.group-card-6 {
  border-color: colours.$charcoal;
}

.group-card-7 {
  border-color: colours.$sunset_orange;
  border-style: dotted;
}

.group-card-8 {
  border-color: plum;
  border-style: dashed;
}

.group-card-9 {
  border-color: burlywood;
}

.comp-group-item-card {
  position: relative;
  .remove-item-button {
    position: absolute;
    top: -5px;
    right: -10px;
    height: 21px;
    width: 20px;
    padding: 0;
  }
}

// css hack fixes - "Am I proud of these, no. Do we need them? Maybe?" - Blaine 2024
// This hack is an attempt to fix how the hypnogram looks on a printed version of this form.
//
.orp-hypnogram-container {
  @media print {
    .recharts-responsive-container {
      max-width: 600px;
      height: auto !important; // css hack fixes - "More sins" - Brad 2024
    }
    .recharts-wrapper {
      width: 600px !important;
    }
    .recharts-legend-wrapper {
      max-width: 590px !important;
      top: 0px !important; // css hack fixes - "More sins" - Brad 2024
    }
  }
}

.comp-decile-bar-chart {
  scale: 1;
}

@media print {
  .no-break {
    break-inside: avoid;
  }

  .break-before {
    break-before: page;
  }

  .decile-bar-chart {
    max-width: 450px;
    max-height: 262px;
  }

  // Note to Future Blaine, maybe do something similar to this for the class above, might be nicer?
  .comp-chart-wrapper {
    width: calc(350px * 0.5); /* Scale width based on the original size */
    height: calc(250px * 0.5); /* Scale height based on the original size */
    overflow: hidden; /* Ensure no extra space is taken up */
  }

  .comp-chart-container {
    transform: scale(0.5); /* Scale the chart */
    transform-origin: top left; /* Scale from the top-left corner */
  }
}
