.login {
  width: 600px;
  margin: 100px auto;
}
.login-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 30px;
  box-shadow: 5px 5px 5px -3px rgba(0, 0, 0, 0.1);
  border: 1px solid #d9d9d9;
  border-radius: 10px 10px 0px 0px;
}
.login-textBox {
  padding: 10px;
  font-size: 18px;
}
.login-btn {
  width: 100%;
  padding: 10px;
  margin: 0;
  font-size: 18px;
  color: white;
  background-color: #3aa0f5;
  border-radius: 0 0 10px 10px;
  border: none;
  outline: none;
}

.login-btn:hover {
  background-color: #007bff;
  border: 2px solid #007bff;
  color: #ffffff;
  border: none;
  outline: none;
}

.login div {
  margin-top: 7px;
}
