.navbar {
  padding: 0 1rem;
  background: linear-gradient(to right, #0096ff 0%, #10cfcc 85%) !important;
  @media print {
    display: none;
  }
}

.navbar-topbar {
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-collapse {
  align-content: center;
  justify-content: end;
}

.navbar .nav-item {
  margin: 0 0.2rem;
}

.navbar .nav-link {
  font-size: 1.2rem;
  color: #ffffff;
}

.navbar .nav-link.active,
.navbar .nav-link.active:hover,
.navbar .nav-item:hover .nav-link {
  color: #ffffff !important;
  text-decoration: underline;
}

.profile-dropdown {
  position: absolute !important;
  left: -120px;
}

.navbar-nav .dropdown-menu {
  right: 0;
  left: auto;
}

.user-icon {
  font-size: 2rem;
  color: #0096ff;
}

.navbar-brand {
  padding: 0.5rem;
}

#profileDropDown.dropdown-toggle::after {
  display: none;
}
